import { Link } from "react-router-dom";
import { useState } from "react";
import { Transition } from "@headlessui/react";

const ProjectsPage = () => {
  const [projects, setProjects] = useState([
    {
      name: 'fysh',
      desc: 'fishing tournament site using react, firebase, and tailwindcss.',
      img: '/img/fysh-demo.png',
      demo: 'https://fysh-app.netlify.app',
      github: 'https://github.com/daitkenbacon/fysh',
      active: false,
    },
    {
      name: 'formr',
      desc: 'a drag-and-drop form creator. uses react, tailwind, and react-dnd.',
      img: '/img/formr-demo.png',
      demo: 'https://formr.netlify.app/',
      github: 'https://github.com/daitkenbacon/app-tracker',
      active: false,
    },
    {
      name: 'wishlist maker',
      desc: 'a demo project for creating a video game wishlist. made with react, typescript, and the rawg api.',
      img: 'https://screenshot-proxy.netlify.app/f_avif,w_336/https://d33wubrfki0l68.cloudfront.net/6387cec874183300099a8ec2/screenshot_2022-11-30-21-45-15-0000.png',
      demo: 'https://rawg-wishlister.netlify.app',
      github: 'https://github.com/daitkenbacon/game-wishlister',
      active: false,
    }
  ]);

  const handleClick = (project) => {
    let updatedProjects = projects.map(p => 
      p.name === project.name ? { ...p, active: !p.active } : p
    );
    setProjects(updatedProjects);
  }

  return (
    <div className="flex lg:m-60 gap-20 flex-col margin-auto justify-center align-center text-center font-courier">

      <div className="flex-col justify-center align-center gap-2 flex margin-auto mt-16">
        <p className="text-4xl justify-center flex cursor-default">projects</p>
        <Link className="flex m-auto text-center hover:opacity-70 hover:translate-y-1 transition-all cursor-pointer" to='/'>(go back)</Link>
      </div>

      <div className="flex-col justify-center align-center gap-2 flex margin-auto mt-16">
        {projects.map((project) => (
              <div key={project.name} className="flex justify-center align-center text-center transition-all flex-col m-auto group">
                <p onClick={() => handleClick(project)} className="text-xl m-auto flex text-center hover:opacity-70 cursor-pointer hover:translate-y-1 transition-all">{project.name}</p>
                <Transition
                  show={project.active}
                  enter="transition duration-150"
                  enterFrom="opacity-0 scale-y-0 translate-y-0 mt-0 mb-0"
                  enterTo="opacity-100 scale-y-100 h-full translate-y-1 mt-5 mb-10"
                  leave="transition h-full duration-150"
                  leaveFrom="opacity-100 scale-y-100 translate-y-1 mt-5 mb-10"
                  leaveTo="opacity-0 scale-y-0 translate-y-0 mt-0 mb-0"
                >
                  <div className="flex items-center justify-center border-t border-b border-opacity-10 border-black">
                    <div className="flex flex-col md:flex-row items-center w-full p-6 max-w-2xl">
                      <img src={project.img} alt="Project" className="mr-4 border border-opacity-50 w-64 object-cover rounded sm:w-64 md:w-72 mb-4 md:mb-0 lg:w-80" />
                      <div className="flex flex-col justify-between">
                        <div>
                          <h2 className="text-2xl font-bold mb-2">{project.name}</h2>
                          <p className="text-gray-800 mb-4">{project.desc}</p>
                        </div>
                        <div className="flex justify-center">
                          {project.demo && 
                          <Link target='_blank' to={project.demo}>
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2">Demo
                            </button>
                          </Link>}
                          {project.github && 
                          <Link to={project.github} target='_blank'>
                            <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded">Github</button>
                          </Link>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
          ))
        }



      </div>

    </div>
  )
}

export default ProjectsPage;