import { Route, Routes } from "react-router-dom";
import AboutPage from "./routes/AboutPage";

import HomePage from "./routes/HomePage";
import ProjectsPage from "./routes/ProjectsPage";
import SkillsPage from "./routes/SkillsPage";

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/projects' element={<ProjectsPage/>}/>
        <Route path='/skills' element={<SkillsPage/>}/>
        <Route path='/about' element={<AboutPage/>}/>
      </Routes>
    </div>
  );
}

export default App;
