import { Link } from "react-router-dom";
import { useState } from "react";
import { Transition } from "@headlessui/react";

const SkillsPage = () => {
  const [skillCategories, setSkillCategories] = useState([
    {
      name: 'front end',
      active: false,
      skills: [
        'javascript',
        'typescript',
        'react',
        'html',
        'css',
        'tailwind'
      ]
    },
    {
      name: 'back end',
      active: false,
      skills: [
        'node.js',
        'google firebase',
        'google cloud functions'
      ]
    },
    {
      name: 'tools & tech',
      active: false,
      skills: [
        'git',
        'npm',
        'agile',
        'adobe suite',
        'office 365'
      ]
    }
  ]);

  const handleClick = (category) => {
    let updatedSkills = skillCategories.map(p => 
      p.name === category.name ? { ...p, active: !p.active } : p
    );
    setSkillCategories(updatedSkills);
  }

  return (
    <div className="flex lg:m-60 gap-20 flex-col margin-auto justify-center align-center text-center font-courier">

      <div className="flex-col justify-center align-center gap-2 flex margin-auto mt-16">
        <p className="text-4xl justify-center flex cursor-default">skills & expertise</p>
        <Link className="flex m-auto text-center hover:opacity-70 hover:translate-y-1 transition-all cursor-pointer" to='/'>(go back)</Link>
      </div>

      <div className="flex-col justify-center align-center gap-2 flex margin-auto mt-16">
        {skillCategories.map((category) => (
              <div key={category.name} className="flex justify-center align-center text-center transition-all flex-col m-auto group">
                <p onClick={() => handleClick(category)} className="text-xl m-auto flex text-center hover:opacity-70 cursor-pointer hover:translate-y-1 transition-all">{category.name}</p>
                <Transition
                  show={category.active}
                  enter="transition duration-150"
                  enterFrom="opacity-0 translate-y-0"
                  enterTo="opacity-100 h-full translate-y-1"
                  leave="transition h-full duration-150"
                  leaveFrom="opacity-100 translate-y-1"
                  leaveTo="opacity-0 translate-y-0"
                >
                  <div className="flex items-center justify-center">
                    <div className="flex flex-col gap-1 items-center w-full p-6 max-w-2xl">
                      {category.skills &&
                        category.skills.map((skill) => (
                          <p key={skill}>{skill}</p>
                        ))
                      }
                    </div>
                  </div>
                </Transition>
              </div>
          ))
        }



      </div>

    </div>
  )
}

export default SkillsPage;