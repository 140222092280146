import { Link } from "react-router-dom";
import { useState } from "react";
import { Transition } from "@headlessui/react";

const AboutPage = () => {
  const [categories, setCategories] = useState([
    {
      name: "the basics",
      body: "i've been coding since i was 13, starting with a C++ FOR DUMMIES book that went way over my head. i have now worked with multiple startups and am consistently juggling personal passion projects. i wholeheartedly believe that when you put people over products, both will thrive.",
      active: false,
    },
    {
      name: "the background",
      body: "i was born and raised in nassau, the bahamas. i grew up in my family's photography studio, spending my childhood trying to teach myself to code and learning about cameras. ever since moving to america as a teenager, that same love of technology and art has defined my career.",
      active: false,
    },
    {
      name: "the boy",
      body: "i love to create. whether that's coding, telling stories through photo & video, or making recipes in the kitchen. i've been tinkering with computers and cameras since i was 7. nothing makes me happier than making something new, solving problems and growing myself along the way.",
      active: false,
    },
  ]);

  const handleClick = (category) => {
    let updatedCategories = categories.map((c) =>
      c.name === category.name ? { ...c, active: !c.active } : c
    );
    setCategories(updatedCategories);
  };

  return (
    <div className="flex lg:m-60 gap-20 flex-col margin-auto justify-center align-center text-center font-courier">
      <div className="flex-col justify-center align-center gap-2 flex margin-auto mt-16">
        <p className="text-4xl justify-center flex cursor-default">
          hey! i'm daniel.
        </p>
        <Link
          className="flex m-auto text-center hover:opacity-70 hover:translate-y-1 transition-all cursor-pointer"
          to="/"
        >
          (go back)
        </Link>
      </div>

      <div className="flex-col justify-center align-center gap-2 flex margin-auto mt-16">
        {categories.map((category) => (
          <div
            key={category.name}
            className="flex justify-center align-center text-center transition-all flex-col m-auto group"
          >
            <p
              onClick={() => handleClick(category)}
              className="text-xl m-auto flex text-center hover:opacity-70 cursor-pointer hover:translate-y-1 transition-all"
            >
              {category.name}
            </p>
            <Transition
              show={category.active}
              enter="transition duration-150"
              enterFrom="opacity-0 translate-y-0"
              enterTo="opacity-100 h-full translate-y-1"
              leave="transition h-full duration-150"
              leaveFrom="opacity-100 translate-y-1"
              leaveTo="opacity-0 translate-y-0"
            >
              <div className="flex items-center justify-center">
                <div className="flex flex-col gap-1 items-center w-full p-6 max-w-2xl">
                  {category.body}
                </div>
              </div>
            </Transition>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutPage;
