import { Link } from "react-router-dom";

const HomePage = () => {
  const intLinks = [
    {
      name: 'projects',
      href: '/projects'
    },
    {
      name: 'skills',
      href: '/skills'
    },
  ]

  const extLinks = [
    {
      name: 'linkedin',
      href: 'https://linkedin.com/in/danieltroyaitken'
    },
    {
      name: 'github',
      href: 'https://github.com/daitkenbacon'
    },
  ]

  return (
    <div className="flex sm:m-60 gap-20 flex-col margin-auto justify-center align-center text-center font-courier">

      <div className="flex-col justify-center align-center gap-2 flex margin-auto mt-16">
        <p className="text-4xl justify-center flex cursor-default">daniel aitken</p>
        <Link className="flex m-auto text-center hover:opacity-70 hover:translate-y-1 transition-all cursor-pointer" to='/about'>(software developer)</Link>
      </div>

      <div className="flex-col justify-center align-center gap-2 flex margin-auto mt-16">
        {intLinks.map((link) => (
            <Link key={link.name} className="flex m-auto" to={link.href}>
              <p className="text-xl flex text-center hover:opacity-70 cursor-pointer hover:translate-y-1 transition-all">{link.name}</p>
            </Link>
          ))
        }
        {extLinks.map((link) => (
            <Link target='_blank' key={link.name} className="flex m-auto" to={link.href}>
              <p className="text-xl flex hover:opacity-70 hover:translate-y-1 transition-all cursor-pointer">{link.name}</p>
            </Link>
          ))
        }
      </div>
      <div className="flex-col text-center justify-center align-center gap-2 flex mx-auto">
        <a href="mailto:daitkenjn@gmail.com" className="text-xl flex flex-col hover:opacity-70 hover:translate-y-1 transition-all cursor-pointer">
          contact
        </a>
      </div>
    </div>
  )
}

export default HomePage;